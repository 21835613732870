@if (starType === 'DIAMOND') {
  <auk-svg-icon-legacy id="star-full" class="blue"></auk-svg-icon-legacy>
}
@if (starType === 'GREEN') {
  <auk-svg-icon-legacy id="leaf"></auk-svg-icon-legacy>
}
@if (starType === 'WHITE') {
  <auk-svg-icon-legacy id="star-empty"></auk-svg-icon-legacy>
}
@if (starType === 'BRONZE') {
  <auk-svg-icon-legacy id="star-full"></auk-svg-icon-legacy>
}
@if (starType === 'SILVER') {
  <auk-svg-icon-legacy id="star-full" class="gray"></auk-svg-icon-legacy>
}
@if (starType === 'GOLD') {
  <auk-svg-icon-legacy id="star-full" class="yellow"></auk-svg-icon-legacy>
}
