import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[aukPopoverContainer]',
})
export class PopoverContainerDirective {

  @Output() public hover: EventEmitter<boolean> = new EventEmitter();
  @Output() public stateChanged: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.popover-container-active') public visible: boolean = false;
  @HostBinding('hidden') public invisible: boolean = true;

  public setVisibility(state: boolean): void {
    this.visible = state;
    this.invisible = !state;
    this.stateChanged.emit(state);
  }

  public show(): void {
    this.setVisibility(true);
  }

  public hide(): void {
    this.setVisibility(false);
  }

  public toggleVisibility(): void {
    this.setVisibility(!this.visible);
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.toggleState(true);
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.toggleState(false);
  }

  private toggleState(state: boolean): void {
    this.hover.next(state);
  }

}
