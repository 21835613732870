import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[aukPopoverCloseIcon]',
})
export class PopoverCloseIconDirective {

  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public emitClose(event: MouseEvent): void {
    event.preventDefault();
    this.close.next();
  }

}
