import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChild,
  Directive,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { PopoverCloseIconDirective } from './popover-close-icon.directive';
import { PopoverContainerDirective } from './popover-container.directive';
import { PopoverToggleDirective } from './popover-toggle.directive';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Directive({
  selector: '[aukPopover]',
})
export class PopoverDirective extends NgUnsubscribe implements OnDestroy, AfterContentInit {

  @Input() public disabled: boolean = false;
  @Input() public showDelay: number = 200;
  @Input() public hideDelay: number = 200;
  @Input() public leaveContainerDelay: number = 200;

  @Output() public popoverVisibilityChanged = new EventEmitter<boolean>();

  /* eslint-disable @angular-eslint/no-forward-ref */
  @ContentChild(forwardRef(() => PopoverToggleDirective), { static: false }) public toggleEl: PopoverToggleDirective;
  @ContentChild(forwardRef(() => PopoverContainerDirective), { static: false }) public containerEl: PopoverContainerDirective;
  @ContentChild(forwardRef(() => PopoverCloseIconDirective), { static: false }) public closeIconEl: PopoverCloseIconDirective;
  /* eslint-enable @angular-eslint/no-forward-ref */

  @HostBinding('class.popover-active') public isPopoverActive: boolean = false;

  private isMouseOverContainer: boolean = false;
  private isMouseOnToggle: boolean = false;
  private enterDelaySub: Subscription;
  private leaveDelaySub: Subscription;
  private leaveContainerSub: Subscription;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterContentInit(): void {
    if (this.toggleEl) {
      this.toggleEl.hover
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((isHoverActive: boolean) => {
          this.activateToggledPopover(isHoverActive);
        });
    }
    if (this.containerEl) {
      this.containerEl.hover
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((isHoverActive: boolean) => {
          if (isHoverActive === true) {
            this.isMouseOverContainer = true;
          } else {
            this.leaveContainerHandled();
          }
        });
    }

    if (this.closeIconEl) {
      this.closeIconEl.close
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => this.setPopoverState(false));
    }
  }

  public closePopover(): void {
    if (this.containerEl) {
      this.containerEl.setVisibility(false);
    }
    this.isPopoverActive = false;
    this.changeDetectorRef.markForCheck();
    this.popoverVisibilityChanged.emit(this.isPopoverActive);
  }

  private setPopoverState(state: boolean = true): void {
    if (this.disabled) {
      return;
    }
    if (this.containerEl) {
      this.containerEl.setVisibility(state);
    }
    this.isPopoverActive = state;
    this.changeDetectorRef.markForCheck();
    this.popoverVisibilityChanged.emit(this.isPopoverActive);
  }

  private enterToggleHandled(): void {
    this.enterDelaySub = this.ngZoneUtilService.timerOut$(this.hideDelay)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.setPopoverState();
        this.clearLeaveSub();
      });
  }

  private leaveToggleHandled(): void {
    this.leaveDelaySub = this.ngZoneUtilService.timerOut$(this.hideDelay)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (!this.isMouseOverContainer) {
          this.setPopoverState(false);
          this.clearTimerSubs();
        }
      });
  }

  private leaveContainerHandled(): void {
    this.isMouseOverContainer = false;
    this.leaveContainerSub = this.ngZoneUtilService.timerOut$(this.leaveContainerDelay)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (!this.isMouseOnToggle) {
          this.setPopoverState(false);
        }
      });
  }

  private activateToggledPopover(state: boolean): void {
    this.clearTimerSubs();
    this.isMouseOnToggle = state;
    if (state) {
      this.enterToggleHandled();
    } else {
      this.leaveToggleHandled();
    }
  }

  private clearEnterSub(): void {
    this.enterDelaySub = this.clearTimerSub(this.enterDelaySub);
  }

  private clearLeaveSub(): void {
    this.leaveDelaySub = this.clearTimerSub(this.leaveDelaySub);
  }

  private clearLeaveContainerSub(): void {
    this.leaveContainerSub = this.clearTimerSub(this.leaveContainerSub);
  }

  private clearTimerSub(sub: Subscription): null {
    if (sub) {
      sub.unsubscribe();
    }
    return null;
  }

  private clearTimerSubs(): void {
    this.clearEnterSub();
    this.clearLeaveSub();
    this.clearLeaveContainerSub();
  }

}
