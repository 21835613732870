export enum Privilege {
    PURCHASE_BUY_NOW_ITEM = 'PURCHASE_BUY_NOW_ITEM',
    BID_ON_ITEM = 'BID_ON_ITEM',
    CREATE_ITEM_BASIC_BAP = 'CREATE_ITEM_BASIC_BAP',
    CREATE_ITEM_EXTENDED_BAP = 'CREATE_ITEM_EXTENDED_BAP',
    PAYMENT_BY_BANK_TRANSFER = 'PAYMENT_BY_BANK_TRANSFER',
    CREATE_NEW_ITEM_FULL_TO_100K = 'CREATE_NEW_ITEM_FULL_TO_100K',
    CREATE_NEW_ITEM_FULL_ABOVE_100K = 'CREATE_NEW_ITEM_FULL_ABOVE_100K',
    API_ACCESS = 'API_ACCESS',
    PREMIUM_BUY = 'PREMIUM_BUY',
    PREMIUM_NEW_ITEM = 'PREMIUM_NEW_ITEM',
    PAYMENT_ON_DELIVERY = 'PAYMENT_ON_DELIVERY',
    BILL_BALANCE = 'BILL_BALANCE',
    EDIT_PERSONAL_DATA = 'EDIT_PERSONAL_DATA',
    BO_ITEM_READ = 'BO_ITEM_READ',
    BO_USER_READ = 'BO_USER_READ',
    BO_CRAZY_PRICES_WRITE = 'BO_CRAZY_PRICES_WRITE',
    APV_WRITE = 'APV_WRITE',
    CATALOG_EXPERT_RECOMMENDED = 'CATALOG_EXPERT_RECOMMENDED',
    CATALOG_EXPERT_OK = 'CATALOG_EXPERT_OK',
    CATALOG_EXPERT_WRONG_CATEGORY = 'CATALOG_EXPERT_WRONG_CATEGORY',
    CATALOG_EXPERT_FORGERY = 'CATALOG_EXPERT_FORGERY',
    SORTING_SCORE_VISUALISATION = 'SORTING_SCORE_VISUALISATION',
    VERIFY_THROUGH_PAYMENT = 'VERIFY_THROUGH_PAYMENT',
    NO_P2P_MESSAGE_CONTACT_VALIDATION = 'NO_P2P_MESSAGE_CONTACT_VALIDATION',
  }
