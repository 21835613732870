import { Component, Input } from '@angular/core';

@Component({
  selector: 'auk-star-icon-legacy',
  templateUrl: './star-icon.component.html',
})
export class StarIconComponent {

  @Input() public starType: any;

}
