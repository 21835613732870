import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter, mergeMap, skip, take, tap } from 'rxjs/operators';
import { UrlService } from '@shared/platform/url.service';
import { Observable, of, takeUntil } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BrowserService } from '@shared/platform/browser.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateRoutesService extends NgUnsubscribe {

  constructor(
    private readonly translateService: TranslateService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly urlService: UrlService,
    private readonly browserService: BrowserService,
  ) {
    super();
  }

  public init(): void {
    this.replaceUrlOnLangChange();
    this.translateUrlOnChange();
  }

  /**
   * When there is router event change it will call resolveUrlEventTranslation
   */
  private translateUrlOnChange(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            (event instanceof NavigationStart || event instanceof NavigationEnd),
        ),
        mergeMap((event) => this.resolveUrlEventTranslation(event as RouterEvent)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * When language will change it will update url with correct translated one.
   */
  private replaceUrlOnLangChange(): void {
    this.translateService.onDefaultLangChange
      .pipe(
        skip(1),
        mergeMap(() => this.translateRoute()),
        tap((translatedUrl) => {
          if (this.browserService.href !== translatedUrl) {
            this.location.replaceState(translatedUrl);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * Logic for Resolving Events from Router
   * This function will check if URL input is already translated to the local language, and if it is, we need to reverse translate it to CZ
   * navigate to that URL (otherwise we would get 404) and then replace this URL with translated one.
   * If the URL is not translated we can skip this step altogether and just replace the URL with translated one.
   * @param event
   */
  private resolveUrlEventTranslation(event: RouterEvent): Observable<string> {
    if (event instanceof NavigationStart) {
      return this.urlService.translateUrl(event.url)
        .pipe(
          mergeMap((translatedUrl) => {
            if (translatedUrl === event.url) {
              return this.urlService.reverseTranslateUrl(event.url)
                .pipe(
                  take(1),
                  tap((reversedTranslatedUrl) => {
                    if (reversedTranslatedUrl !== event.url) {
                      void this.router.navigateByUrl(reversedTranslatedUrl, { skipLocationChange: true });
                    }
                  }),
                );
            } else {
              return of(null);
            }
          }),
        );
    }

    return this.translateRoute()
      .pipe(tap((translatedUrl) => {
        if (this.browserService.href !== translatedUrl) {
          this.location.replaceState(translatedUrl);
        }
      }));
  }

  private translateRoute(): Observable<string> {
    return this.urlService.translateUrl(this.router.url);
  }

}
