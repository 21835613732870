import { Injectable } from '@angular/core';

/**
 * This class can be used as injectable Angular service as well as simple static class
 */
@Injectable({
  providedIn: 'root',
})
export class BuildInfoService {

  public static readonly version: string = '2024-12-02-r18';
  public readonly version: string = BuildInfoService.version;

}
